import { render, staticRenderFns } from "./PrecoRevista.vue?vue&type=template&id=6594a30a&scoped=true&lang=pug&"
import script from "./PrecoRevista.vue?vue&type=script&lang=js&"
export * from "./PrecoRevista.vue?vue&type=script&lang=js&"
import style0 from "./PrecoRevista.styl?vue&type=style&index=0&id=6594a30a&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6594a30a",
  null
  
)

export default component.exports